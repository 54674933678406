const filterTruckTypeName = (arr, name) => {
  return arr.some(item => {
    return name.indexOf(item) > -1;
  });
};
const filterEnergyType = (arr, type, allowTypeNull = true) => {
  if (!type) {
    return allowTypeNull;
  }
  return arr.indexOf(type) > -1;
};

const filterNumberGroup = (filters, group) => {
  if (filters.length < group.length) return false;
  return filters.every((filter, index) => {
    const temp = { max: Infinity, min: -Infinity, ...filter };
    const targetNumber = group[index];
    if (typeof targetNumber !== 'number') return false;
    return targetNumber >= temp.min && targetNumber < temp.max;
  });
};

const truckTypeAndEnergyTypeFactory = ({ nameFilter, typeFilter, truckTypeName, energyType }, allowTypeNull = true) => {
  if (
    truckTypeName &&
    filterTruckTypeName(nameFilter, truckTypeName) &&
    filterEnergyType(typeFilter, energyType, allowTypeNull)
  )
    return true;
  return false;
};

const energyTypeNumberGroupFactory = ({ numberFilter, numberGroup, typeFilter, energyType }, allowTypeNull = true) => {
  const isAllNumberSet = numberGroup.every(it => typeof it === 'number');
  if (
    isAllNumberSet &&
    filterNumberGroup(numberFilter, numberGroup) &&
    filterEnergyType(typeFilter, energyType, allowTypeNull)
  )
    return true;
  return false;
};

export const getColorStrategy = () => {
  const lists = [
    {
      validate: source => {
        //「车辆类型」包含这些文字「重型」，「中型」，「牵引」；且能源类型为“汽油”“柴油”“天然气”或“未填”
        const { truckTypeName, energyType } = source;
        const nameFilter = ['重型', '中型', '牵引'];
        const typeFilter = ['A', 'B', 'E'];
        debugger;
        return truckTypeAndEnergyTypeFactory({ nameFilter, typeFilter, truckTypeName, energyType });
      },
      value: '2' //黄色
    },
    {
      validate: source => {
        //车身外廓尺寸长度 > =  6000mm，且总质量 > = 4500kg，且核定载质量 > = 2000kg；且能源类型为“汽油”“柴油”“天然气”或“未填”
        const { energyType, outlineDimensionLength, totalWeight, loadWeight } = source;
        const numberFilter = [{ min: 6000 }, { min: 4500 }, { min: 2000 }]; //外廓长度、总质量、核定载质量
        const numberGroup = [outlineDimensionLength, totalWeight, loadWeight];
        const typeFilter = ['A', 'B', 'E'];
        debugger;
        return energyTypeNumberGroupFactory({ numberFilter, numberGroup, typeFilter, energyType });
      },
      value: '2' //黄色
    },
    {
      validate: source => {
        //「车辆类型」包含这些文字「轻型」，「微型」；且能源类型为“汽油”“柴油”“天然气”或“未填”
        const { truckTypeName, energyType } = source;
        const nameFilter = ['轻型', '微型'];
        const typeFilter = ['A', 'B', 'E'];
        return truckTypeAndEnergyTypeFactory({ nameFilter, typeFilter, truckTypeName, energyType });
      },
      value: '1' //蓝色
    },
    {
      validate: source => {
        //车身外廓尺寸长度 < 6000mm，且总质量 < 4500kg，且核定载质量 > 2000kg；且能源类型为“汽油”“柴油”“天然气”或“未填”
        const { energyType, outlineDimensionLength, totalWeight, loadWeight } = source;
        const numberFilter = [{ max: 6000 }, { max: 4500 }, { max: 2000 }]; //外廓长度、总质量、核定载质量
        const numberGroup = [outlineDimensionLength, totalWeight, loadWeight];
        const typeFilter = ['A', 'B', 'E'];
        return energyTypeNumberGroupFactory({ numberFilter, numberGroup, typeFilter, energyType });
      },
      value: '1' //蓝色
    },
    {
      validate: source => {
        //「车辆类型」包含这些文字「重型」，「中型」，「牵引」；且能源类型为“电”“太阳能”“混合动力”
        const { truckTypeName, energyType } = source;
        const nameFilter = ['重型', '中型', '牵引'];
        const typeFilter = ['C', 'N', 'O'];
        return truckTypeAndEnergyTypeFactory({ nameFilter, typeFilter, truckTypeName, energyType }, false);
      },
      value: '93' //黄绿色
    },
    {
      validate: source => {
        //车身外廓尺寸长度 > =  6000mm，且总质量 > = 4500kg，且核定载质量 > = 2000kg；且能源类型为“电”“太阳能”“混合动力”
        const { energyType, outlineDimensionLength, totalWeight, loadWeight } = source;
        const numberFilter = [{ min: 6000 }, { min: 4500 }, { min: 2000 }]; //外廓长度、总质量、核定载质量
        const numberGroup = [outlineDimensionLength, totalWeight, loadWeight];
        const typeFilter = ['C', 'N', 'O'];
        return energyTypeNumberGroupFactory({ numberFilter, numberGroup, typeFilter, energyType }, false);
      },
      value: '93' //黄绿色
    },
    {
      validate: source => {
        //「车辆类型」包含这些文字「轻型」，「微型」；且能源类型为“电”“太阳能”“混合动力”
        const { truckTypeName, energyType } = source;
        const nameFilter = ['轻型', '微型'];
        const typeFilter = ['C', 'N', 'O'];
        return truckTypeAndEnergyTypeFactory({ nameFilter, typeFilter, truckTypeName, energyType }, false);
      },
      value: '94' //渐变绿
    },
    {
      validate: source => {
        //车身外廓尺寸长度 <  6000mm，且总质量 < 4500kg，且核定载质量 < 2000kg；且能源类型为“电”“太阳能”“混合动力”
        const { energyType, outlineDimensionLength, totalWeight, loadWeight } = source;
        const numberFilter = [{ max: 6000 }, { max: 4500 }, { max: 2000 }]; //外廓长度、总质量、核定载质量
        const numberGroup = [outlineDimensionLength, totalWeight, loadWeight];
        const typeFilter = ['C', 'N', 'O'];
        return energyTypeNumberGroupFactory({ numberFilter, numberGroup, typeFilter, energyType }, false);
      },
      value: '94' //渐变绿
    }
  ];
  return lists;
};

export const useColorStrategy = () => {
  const list = getColorStrategy();
  const runStrategy = (source, callback) => {
    for (let index = 0; index < list.length; index++) {
      const strategy = list[index];
      const isMatch = strategy.validate(source);
      debugger;
      if (isMatch) {
        const returnValue = strategy.value;
        return callback(returnValue);
      }
    }
  };

  return {
    list,
    runStrategy
  };
};
