<template>
  <div>
    <Popup ref="popup" :mask-click="false" background-color="#fff" v-model="showPopup">
      <div class="content px-24 pt-24 box-border">
        <div class="text-center font-500 text-18 text-main mb-40">确认车辆信息</div>
        <div class="flex justify-between items-center mb-24">
          <div class="text-muted text-16">车牌号</div>
          <div class="text-main text-16"></div>
          <span>{{ carInfo.carLicensePlate }}</span
          ><span v-if="carInfo.plateColor">{{ carInfo.plateColor }}</span>
        </div>

        <div class="flex justify-between items-center mb-24">
          <div class="text-muted text-16">车型</div>
          <div class="text-main text-16">{{ carInfo.truckTypeName }}</div>
        </div>
        <div class="flex justify-between items-center mb-24">
          <div class="text-muted text-16">{{ isTrailer ? '准牵引总质量' : '核定载质量' }}</div>
          <div class="text-main text-16">{{ carInfo.loadWeight }}kg</div>
        </div>
        <div class="flex justify-between items-center mb-12">
          <div class="text-muted text-16">总质量</div>
          <div class="text-main text-16">{{ carInfo.totalWeight }}kg</div>
        </div>
        <div class="text-warning text-12" v-if="carInfo.totalWeight <= 4500">请确认质量是否准确</div>
      </div>

      <div class="btn-style flex justify-between items-center">
        <div class="m-class text-16 text-center text-main flex-1 py-14" @click="handleModify">返回修改</div>
        <div
          class="text-16 text-center text-primary flex-1 py-14"
          :class="{ disabled: count > 0 }"
          @click="handleComfirm"
        >
          <span v-if="count > 0" style="margin-right: 2px">({{ count }}s)</span> {{ btnText }}
        </div>
      </div>
    </Popup>
  </div>
</template>

<script>
import { useCountdown } from './utils';
const { startCount, stopCount } = useCountdown(3);
import { Popup } from 'vant';
import { checkTrailerBytype } from '../constants';
export default {
  components: { Popup },
  props: {
    carInfo: { type: Object, default: () => ({}) }
  },
  data() {
    return {
      btnText: '确认并提交',
      count: 0,
      showPopup: false
    };
  },
  watch: {
    showPopup(n) {
      if (n) {
        startCount(count => {
          this.count = count;
        });
      } else {
        stopCount();
      }
    }
  },
  beforeDestroy() {
    stopCount();
  },
  computed: {
    isTrailer() {
      const truckTypeCode = this.carInfo.truckTypeCode;
      return !!truckTypeCode && checkTrailerBytype(truckTypeCode);
    }
  },
  methods: {
    handleModify() {
      this.$emit('handleModify');
      this.showPopup = false;
    },
    handleComfirm() {
      if (this.count > 0) return;
      this.showPopup = false;
      this.$emit('handleComfirm');
    },
    show() {
      this.showPopup = true;
    }
  }
};
</script>

<style lang="less" scoped>
.content {
  width: 300px;
}
.disabled {
  opacity: 0.3;
}
.btn-style {
  margin-top: 32px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.m-class {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
